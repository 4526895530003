var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "70%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "2vh",
            title: "Thêm đại lý",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "createTabAgent",
              attrs: {
                "label-width": "140px",
                model: _vm.createTabAgent,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Tên đại lý", prop: "agentName" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.createTabAgent.agentName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.createTabAgent, "agentName", $$v)
                                },
                                expression: "createTabAgent.agentName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Địa chỉ", prop: "agentAddress" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.createTabAgent.agentAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createTabAgent,
                                    "agentAddress",
                                    $$v
                                  )
                                },
                                expression: "createTabAgent.agentAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Email", prop: "agentEmail" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.createTabAgent.agentEmail,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createTabAgent,
                                    "agentEmail",
                                    $$v
                                  )
                                },
                                expression: "createTabAgent.agentEmail",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Điện thoại",
                              prop: "contactPhone",
                              align: "right",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.createTabAgent.contactPhone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createTabAgent,
                                    "contactPhone",
                                    $$v
                                  )
                                },
                                expression: "createTabAgent.contactPhone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Website", prop: "website" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.createTabAgent.website,
                                callback: function ($$v) {
                                  _vm.$set(_vm.createTabAgent, "website", $$v)
                                },
                                expression: "createTabAgent.website",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Dùng thử từ", prop: "demoStart" },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                format: "dd-MM-yyyy",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "Chọn ngày",
                              },
                              model: {
                                value: _vm.createTabAgent.demoStart,
                                callback: function ($$v) {
                                  _vm.$set(_vm.createTabAgent, "demoStart", $$v)
                                },
                                expression: "createTabAgent.demoStart",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Đến ngày", prop: "demoEnd" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                format: "dd-MM-yyyy",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "Chọn ngày",
                              },
                              model: {
                                value: _vm.createTabAgent.demoEnd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.createTabAgent, "demoEnd", $$v)
                                },
                                expression: "createTabAgent.demoEnd",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Ngày bắt đầu", prop: "dateStart" },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                format: "dd-MM-yyyy",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "Chọn ngày",
                              },
                              model: {
                                value: _vm.createTabAgent.dateStart,
                                callback: function ($$v) {
                                  _vm.$set(_vm.createTabAgent, "dateStart", $$v)
                                },
                                expression: "createTabAgent.dateStart",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Ngày hết hạn", prop: "dateEnd" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                format: "dd-MM-yyyy",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "Chọn ngày",
                              },
                              model: {
                                value: _vm.createTabAgent.dateEnd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.createTabAgent, "dateEnd", $$v)
                                },
                                expression: "createTabAgent.dateEnd",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Ngày ký HĐ",
                              prop: "dateContractStart",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                format: "dd-MM-yyyy",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "Chọn ngày",
                              },
                              model: {
                                value: _vm.createTabAgent.dateContractStart,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createTabAgent,
                                    "dateContractStart",
                                    $$v
                                  )
                                },
                                expression: "createTabAgent.dateContractStart",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Kết thúc HĐ",
                              prop: "dateContractEnd",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                format: "dd-MM-yyyy",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "Chọn ngày",
                              },
                              model: {
                                value: _vm.createTabAgent.dateContractEnd,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createTabAgent,
                                    "dateContractEnd",
                                    $$v
                                  )
                                },
                                expression: "createTabAgent.dateContractEnd",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { prop: "agentActive", label: "Trạng thái" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.createTabAgent.agentActive,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.createTabAgent,
                                      "agentActive",
                                      $$v
                                    )
                                  },
                                  expression: "createTabAgent.agentActive",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Kích hoạt"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Hủy"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "smsActiveMore",
                              label: "Sử dụng SMS",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.createTabAgent.smsActiveMore,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.createTabAgent,
                                      "smsActiveMore",
                                      $$v
                                    )
                                  },
                                  expression: "createTabAgent.smsActiveMore",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Mặc định"),
                                ]),
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Vượt mức"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "smsBudget", label: "Mức SMS" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.createTabAgent.smsBudget,
                                callback: function ($$v) {
                                  _vm.$set(_vm.createTabAgent, "smsBudget", $$v)
                                },
                                expression: "createTabAgent.smsBudget",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Ghi chú",
                              prop: "agentDescription",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { type: "textarea", rows: 5 },
                              model: {
                                value: _vm.createTabAgent.agentDescription,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createTabAgent,
                                    "agentDescription",
                                    $$v
                                  )
                                },
                                expression: "createTabAgent.agentDescription",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-infor-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialogByButton("createTabAgent")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v("Đóng")]),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButton,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("createTabAgent")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }